import axios from 'axios'
import { GET_ALL_USERS } from "../Helpers/ActionTypes";
import { getBackendBaseUrl } from "../Helpers/Environment";
import { getHeaders, saveToken } from "../Helpers/Requests";
import { history } from '../App'

export interface UserResponse {
    id: number,
    name: string,
}

export interface UserRequest {
    name: string,
    password: string,
}

export const allUsersData = (allUsers: UserResponse[]) => ({
    type: GET_ALL_USERS,
    data: allUsers,
})

export const getAllUsers = () => {
    return (dispatch: any) => {
        return axios.get(getBackendBaseUrl() + '/users', getHeaders())
        .then(response => {
            saveToken(response);
            return dispatch(allUsersData(response.data))
        })
    }
}

export const signUp = (signUpData: UserRequest) => {
    return (dispatch: any) => {
        return axios.post(getBackendBaseUrl() + '/users', {
            name: signUpData.name,
            password: signUpData.password,
        }).then(() => history.push('/login'));
    }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loginAndFetchUserData } from '../Actions';
import { Paper, TextField, Button } from '@material-ui/core'

class LoginPage extends Component<{loginAndFetchUserData: any}> {
  state = {
    name: "",
    password: "",
  }

  handleChange = (event: any) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = (event: any) => {
    event.preventDefault()
    this.props.loginAndFetchUserData(this.state)
  }

    render() {
        return (
            <Paper elevation={5} style={{padding:'1rem', marginRight: 'auto', marginLeft: 'auto', width: '50%', textAlign:'center'}}>
                <form onSubmit={this.handleSubmit}>
                    <h1>Login</h1>

                    <TextField
                        name="name"
                        label="Profilname"
                        value={this.state.name}
                        onChange={this.handleChange} />
                    <br />
                    <TextField
                        name="password"
                        type="password"
                        label="Passwort"
                        value={this.state.password}
                        onChange={this.handleChange} />
                    <br />
                    <br />
                    <Button color='primary' variant='contained' type='submit'>Weiter</Button>
                </form>
            </Paper>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => ({
  loginAndFetchUserData: (userInfo: any) => dispatch(loginAndFetchUserData(userInfo))
})

export default connect(null, mapDispatchToProps)(LoginPage);
import axios from 'axios'
import { getBackendBaseUrl } from '../Helpers/Environment'
import { getHeaders, saveToken } from '../Helpers/Requests'
import {
    LOGGED_IN_USER,
    LOGGED_IN_USER_DATA,
    LOGOUT,
} from '../Helpers/ActionTypes'


export const loggedinUserId = (id: number) => ({
    type: LOGGED_IN_USER,
    userId: id,
})

export const loggedinUserData = (userData: any) => ({
    type: LOGGED_IN_USER_DATA,
    data: userData,
})

export const logoutData = () => ({
    type: LOGOUT,
})

export const userLoginFetch = (loginData: {name: string, password: string}) => {
    return function(dispatch: any) {
      return axios.post(getBackendBaseUrl() + '/auth/login', {
          name: loginData.name,
          password: loginData.password,
      })
        .then(({ data }) => {
            localStorage.setItem('token', data.token)
            dispatch(loggedinUserId(data.id));
      });
    };
}

export const userDataFetch = (id: number) => {
    return (dispatch: any) => {
        return axios.get(getBackendBaseUrl() + '/users/' + id, getHeaders())
        .then(response => {
            saveToken(response)
            return dispatch(loggedinUserData(response.data));
        })
    }
}

export const loginAndFetchUserData = (loginData: {name: string, password: string}) => {
    return (dispatch: any, getState: any) => {
        return dispatch(userLoginFetch(loginData)).then(() => {
            const userId = getState().loginReducer.currentUserId;
            return dispatch(userDataFetch(userId))
        })
    }
}

export const logoutAndClear = () => {
    return (dispatch: any) => {
       dispatch(logoutData());
    };
}
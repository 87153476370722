import { combineReducers } from 'redux'
import loginReducer from './loginReducer'
import loggedInUser from './loggedInUser'
import otherUsers from './otherUsers'
import allGames from './allGames'
import currentGame from './currentGame'
import { LOGOUT } from '../Helpers/ActionTypes'


const appReducer = combineReducers({ loginReducer, loggedInUser, otherUsers, allGames, currentGame })

const rootReducer = (state: any, action: any) => {   
    if(action.type === LOGOUT){
        state = undefined;
    }
    
    return appReducer(state, action);
 };

export default rootReducer;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllUsers } from '../Actions/user';
import { postNewGame } from '../Actions/game'

interface CreateGamePageProps {
    postNewGame: any,
    getAllUsers: any,
    allUsersData: any,
    ownId: number | undefined,
}

interface CreateGamePageState {
  otherPlayerId: number | undefined,
  sizeX: number | undefined,
  sizeY: number | undefined,
}

class CreateGamePage extends Component<CreateGamePageProps> {
  state: CreateGamePageState = {
    otherPlayerId: undefined,
    sizeX: 10,
    sizeY: 10,
  }

  componentDidMount() {
    this.props.getAllUsers()
  }

  handleChange = (event: any) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = (event: any) => {
    event.preventDefault()
    this.state.otherPlayerId = this.state.otherPlayerId
    || this.props.allUsersData.find((user: any) => user.id != this.props.ownId).id;

    this.props.postNewGame(this.state)
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <h1>Neues Spiel</h1>

        <label>Zweiter Spieler: </label>
        <select
          name='otherPlayerId'
          value={this.state.otherPlayerId || this.props.allUsersData?.find((user: any) => user.id !== this.props.ownId).id}
          onChange={this.handleChange}
        >
            {
                this.props.allUsersData?.map(
                    (user: {name: string, id: number}) => 
                        user.id !== this.props.ownId && <option value={user.id}>{user.name}</option>
                )
            }
        </select>

        <input type='submit'/>
      </form>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => ({
    postNewGame: (userInfo: any) => dispatch(postNewGame(userInfo)),
    getAllUsers: () => dispatch(getAllUsers())
})

const mapStateToProps = (state: any) => ({
    allUsersData: state.otherUsers,
    ownId: state.loggedInUser.data?.id,
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateGamePage);
import { GET_CURRENT_GAME_STATUS, POST_GAME_MOVE, REMOVE_CURRENT_GAME } from '../Helpers/ActionTypes'

const otherUsers = (state = {currentGameStatus: undefined}, action: any) => {
    switch(action.type) {
        case POST_GAME_MOVE:
        case GET_CURRENT_GAME_STATUS:
            action.data.state = JSON.parse(action.data.state);
            return action.data
        case REMOVE_CURRENT_GAME:
            return null;
        default:
            return state
    }
}

export default otherUsers
import { AxiosResponse } from 'axios'

const STORAGE_TOKEN_KEY = 'token'

export function getHeaders() {
    return { headers: { 'auth': localStorage.getItem(STORAGE_TOKEN_KEY)} }
}

export function saveToken(response: AxiosResponse) {
    localStorage.setItem(STORAGE_TOKEN_KEY, response.headers.token)
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getAllUsers } from '../Actions/user';
import { getAllGames, GameResponse } from '../Actions/game'
import { List, ListItem, Paper, Button, Fab, Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import SendIcon from '@material-ui/icons/Send';
import AddIcon from '@material-ui/icons/Add';

interface ListGamesPageProps {
    getAllGames: any,
    allGames: GameResponse[],
    getAllUsers: any,
    allUsers: any[],
    ownId: number,
}

const StyledPaper = styled(Paper)`
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-right: auto;
    margin-left: auto;
`

class ListGamesPage extends Component<ListGamesPageProps> {
  componentDidMount() {
    this.props.getAllGames();
    this.props.getAllUsers();
  }

  handleChange = (event: any) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = (event: any) => {
    event.preventDefault()
  }

    render() {
        return (
            <div style={{textAlign:'center'}}>
                <h1>Spielen</h1>
                <List style={{marginLeft: 'auto', marginRight: 'auto'}}>
                {
                this.props.allGames && this.props.allGames.length > 0 ? 
                this.props.allGames?.map((game) =>     
                    <ListItem>
                        <StyledPaper>
                            Spiel mit {this.props.allUsers?.find((user: any) =>
                                (user.id == game.otherPlayerId && game.otherPlayerId != this.props.ownId)
                                || (user.id == game.creatorId && game.creatorId != this.props.ownId)).name}
                            <Button
                                style={{marginLeft: '1rem'}}
                                component={Link}
                                to={"/game/" + game.id}
                                endIcon={<SendIcon />}
                                variant='outlined'
                                >
                                    Spielen
                                </Button>
                        </StyledPaper>
                    </ListItem>
                ) : <h3>Hier ist es aber leer :( <br/> Erstelle ein neues Spiel</h3>
                }
                </List>
                <Tooltip title='Neues Spiel erstellen'>
                    <Fab color='primary' component={Link} to='/create-game'>
                        <AddIcon />
                    </Fab>
                </Tooltip>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    getAllUsers: () => dispatch(getAllUsers()),
    getAllGames: () => dispatch(getAllGames())
})

const mapStateToProps = (state: any) => ({
    allGames: state.allGames,
    allUsers: state.otherUsers,
    ownId: state.loginReducer.currentUserId,
});

export default connect(mapStateToProps, mapDispatchToProps)(ListGamesPage);
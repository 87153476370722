import { LOGGED_IN_USER } from '../Helpers/ActionTypes'

const initialState = {
    currentUserId: null
}

const loginReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case LOGGED_IN_USER:
            return {...state, currentUserId: action.userId}
        default:
            return state
    }
}

export default loginReducer
import React from 'react';
import { Link} from 'react-router-dom';
import { connect } from "react-redux";
import styled from 'styled-components';
import { Button, Avatar, Tooltip } from '@material-ui/core';
import { TooltipAvatar } from './TooltipAvatar';

interface NavBarProps {
    userId: number,
    userName: string,
}

const FlexRight = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 0.4rem;
    margin-bottom: 1rem;
`

const NavBar = (props: NavBarProps) => {
    const { userId, userName } = props

    if (userId) {
        return (
            <FlexRight>
                <TooltipAvatar style={{marginRight:'auto'}} userName={userName}/>
                <Button variant='outlined' style={{marginRight: '2rem'}} component={Link} to='/list-games'>Spielen</Button>
                <Button style={{marginRight: '2rem'}} component={Link} to='/'>Inspiration</Button>
                <Button style={{marginRight: '2rem'}} component={Link} to='/account'>Einstellungen</Button>
                <Button style={{marginRight: '2rem'}} component={Link} to='/logout'>Ausloggen</Button>
            </FlexRight>
        );
    } else {
        return (
            <FlexRight>
                <Button style={{marginRight: '2rem'}} component={Link} to='/'>Home</Button>
                <Button style={{marginRight: '2rem'}} component={Link} to='/signup'>Acount erstellen</Button>
                <Button variant='outlined' style={{marginRight: '2rem'}} component={Link} to='/login'>Einloggen</Button>
            </FlexRight>
        );
    }
}

const mapStateToProps = (state: any) => ({
    userId: state.loginReducer.currentUserId,
    userName: state.loggedInUser.data?.name,
});

export default connect(mapStateToProps)(NavBar);
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router";

const AuthRoute = (props: any) => {
  const { userId, type } = props;
  if (type === "public" && userId) return <Redirect to="/list-games" />;
  else if (type === "private" && !userId) return <Redirect to="/" />;

  return <Route {...props} />;
};

const mapStateToProps = (state: any) => ({
    userId: state.loginReducer.currentUserId
});

export default connect(mapStateToProps)(AuthRoute);
import { GET_ALL_USERS } from '../Helpers/ActionTypes'

const otherUsers = (state = null, action: any) => {
    switch(action.type) {
        case GET_ALL_USERS:
            return [...action.data]
        default:
            return state
    }
}

export default otherUsers
import React from 'react';
import './App.css';
import { Switch, Router } from 'react-router-dom'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import rootReducer from './Reducers'
import LoginPage from './Pages/LoginPage';
import LogoutPage from './Pages/LogoutPage'
import AuthRoute from './Components/AuthRoute';
import NavBar from './Components/NavBar'
import SignUpPage from './Pages/SignUpPage';
import CreateGamePage from './Pages/CreateGamePage';
import ListGamesPage from './Pages/ListGamesPage';
import PlayGamePage from './Pages/PlayGamePage';
import heavenImage from './Images/heaven.jpg'
import { Paper } from '@material-ui/core';
import createBrowserHistory from 'history/createBrowserHistory'

function saveToLocalStorage(state: any) {
  try {
    const jsonState = JSON.stringify(state)
    localStorage.setItem('state', jsonState)
  } catch (exception) {
    console.log(exception)
  }
}

function getFromLocalStorage() {
  try {
    const jsonState = localStorage.getItem('state')
    if (jsonState === null) return undefined
    return JSON.parse(jsonState)
  } catch (exception) {
    return undefined
  }
}

const stateFromLocalStorage = getFromLocalStorage()

const store = createStore(rootReducer, stateFromLocalStorage, composeWithDevTools(
  applyMiddleware(thunk)
));

store.subscribe(() => saveToLocalStorage(store.getState()))

export const history = createBrowserHistory()

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <NavBar/>
        <Switch>
          <AuthRoute path='/login' type='public'>
            <LoginPage/>
          </AuthRoute>
          <AuthRoute path='/logout' type='private'>
            <LogoutPage/>
          </AuthRoute>
          <AuthRoute path='/signup' type='public' component={SignUpPage} />
          <AuthRoute path='/list-games' type='private'>
            <ListGamesPage/>
          </AuthRoute>
          <AuthRoute path='/create-game' type='private'>
            <CreateGamePage/>
          </AuthRoute>
          <AuthRoute path='/game/:gameId' type='private' component={PlayGamePage} />
          <AuthRoute path='/account' type='private'>
            <a>View your account info</a>
          </AuthRoute>
          <AuthRoute path='/'>
            <div>
              <h1 style={{textAlign:'center'}}>4 Gewinnt Spieleparadies</h1>
              <Paper
                elevation={20}
                  style={
                    {
                      backgroundImage:`url(${heavenImage})`,
                      backgroundSize: 'contain',
                      padding: '0.1rem',
                      marginRight: 'auto',
                      marginLeft: 'auto',
                      width: '80%',
                      paddingTop: '38%',
                      textAlign:'center'
                    }
                  } />
            </div>
          </AuthRoute>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getGameState, postGameMove, removeCurrentGame } from '../Actions/game';
import { range } from '../Helpers/Math';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton'

interface PlayGamePageProps {
    game: any,
    ownId: number | undefined,
    match: any,
    postGameMove: any,
    getGameState: any,
    removeGameState: any,
}

const StyledTable = styled.table`
    text-align: center;
    margin-left: auto;
    margin-right: auto;
`

const StyledTd = styled.td`
    padding-right: 1.2rem;
    padding-left: 1.2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: xx-large;
`   

class PlayGamePage extends Component<PlayGamePageProps> {
    state = {
        nextMove: undefined,
    }
    intervalHandle: number | undefined = undefined;

    componentDidMount() {
        this.intervalHandle = setInterval(() => this.props.getGameState(this.props.match.params.gameId), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalHandle)
        this.props.removeGameState()
    }

    isUsersMove() {
        let usersCount = 0;
        let otherCount = 0;

        this.props.game.state.field.forEach((element: number) => {
            if (element === this.props.ownId) {
                usersCount++;
            } else if(element !== 0) {
                otherCount++;
            }
        });

        if (this.props.game.creatorId === this.props.ownId) {
            return usersCount - otherCount <= 0;
        } else {
            return usersCount - otherCount < 0;
        }
    }

    handleChange = (event: any) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }


    handleSubmit = (event: any) => {
        event.preventDefault()
        this.props.postGameMove({
            gameId: this.props.match.params.gameId,
            lane: event.target.attributes.getNamedItem('data-column').value
        })
    }

    render() {
        const gameId = this.props.match.params.gameId;

        return (
        <div>
            <h1>{gameId}</h1>
            <h2 style={ {textAlign: 'center'} }>{
                (
                    this.props.game
                    && this.props.game.state
                    && (this.isUsersMove() ? 'Du bist dran' : 'Dein Gegner ist dran')
                ) || <Skeleton/>
                }</h2>
            <StyledTable>
            {
            (this.props.game && this.props.game.state && range(this.props.game.sizeX).map((yIndex) => ( 
                <tr>
                    {this.props.game.state.field.map(
                        (fieldValue: number, xIndex: number) => 
                        (xIndex < (yIndex + 1) * this.props.game.sizeX) && (xIndex >= yIndex * this.props.game.sizeX) && 
                        <StyledTd data-column={xIndex % 10} onClick={this.handleSubmit}>{fieldValue !== this.props.ownId ? (fieldValue !== 0 ? 'X': '-') : 'O'}</StyledTd>)}
                </tr>
            ))) || range(10).map(() => (
                <tr>
                    {    
                        <StyledTd><Skeleton height={'0.8rem'} width={'20rem'}/></StyledTd>
                    }
                </tr>
            ))
            }
            </StyledTable>
        </div>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    postGameMove: (gameMoveInfo: {gameId: number, lane: number}) => dispatch(postGameMove(gameMoveInfo)),
    getGameState: (gameId: number) => dispatch(getGameState(gameId)),
    removeGameState: () => dispatch(removeCurrentGame()),
})

const mapStateToProps = (state: any) => ({
    allUsersData: state.otherUsers.allUsers,
    ownId: state.loggedInUser.data?.id,
    game: state.currentGame,
});

export default connect(mapStateToProps, mapDispatchToProps)(PlayGamePage);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logoutAndClear } from '../Actions';

class LoginPage extends Component<{logoutAndClear: any}> {
  componentDidMount() {
    this.props.logoutAndClear()
  }

  render() {
    return (
      <h1>Tschaui</h1>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  logoutAndClear: () => dispatch(logoutAndClear())
})

export default connect(null, mapDispatchToProps)(LoginPage);
import { LOGGED_IN_USER_DATA } from '../Helpers/ActionTypes'

const initialState = {
    data: null
}

const loggedInUser = (state = initialState, action: any) => {
    switch(action.type) {
        case LOGGED_IN_USER_DATA:
            return {...state, data: action.data}
        default:
            return state
    }
}

export default loggedInUser
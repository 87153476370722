import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signUp } from '../Actions/user';
import { TextField, Paper, Button } from '@material-ui/core';
import { withRouter } from "react-router-dom"

class SignUpPage extends Component<{signUp: any}> {
  state = {
    name: "",
    password: "",
    secondPassword: "",
  }

  handleChange = (event: any) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit = (event: any) => {
    event.preventDefault()
    this.props.signUp(this.state)
  }

  render() {
    return (
      <Paper elevation={5} style={{padding:'1rem', marginRight: 'auto', marginLeft: 'auto', width: '50%', textAlign:'center'}}>
                <form onSubmit={this.handleSubmit}>
                    <h1>Account erstellen</h1>

                    <TextField
                        name="name"
                        label="Profilname"
                        value={this.state.name}
                        onChange={this.handleChange} />
                    <br />
                    <br />
                    <TextField
                        name="password"
                        type="password"
                        label="Passwort"
                        value={this.state.password}
                        onChange={this.handleChange} />
                    <br />
                    <TextField
                        error={ this.state.password !== this.state.secondPassword}
                        name="secondPassword"
                        type="password"
                        label="Passwort wiederholen"
                        value={this.state.secondPassword}
                        onChange={this.handleChange} />
                    <br />
                    <br />
                    <Button color='primary' variant='contained' type='submit'>Weiter</Button>
                </form>
            </Paper>
    )
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  signUp: (userInfo: any) => dispatch(signUp(userInfo))
})

export default connect(null, mapDispatchToProps)(SignUpPage)
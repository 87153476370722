import { GET_ALL_GAMES, GET_CURRENT_GAME_STATUS, POST_NEW_GAME, POST_GAME_MOVE, REMOVE_CURRENT_GAME } from "../Helpers/ActionTypes"
import axios from 'axios'
import {getBackendBaseUrl} from '../Helpers/Environment'
import {saveToken, getHeaders} from '../Helpers/Requests'
import { history } from '../App'

export interface GameResponse {
    id: number,
    state: string,
    creatorId: number,
    otherPlayerId: number,
    sizeX: number,
    sizeY: number,
}

export interface GameRequest {
    creatorId: number,
    otherPlayerId: number,
    sizeX: number,
    sizeY: number,
}

export interface GameMoveRequest {
    gameId: number,
    lane: number,
}

export const allGamesData = (gamesData: any) => ({
    type: GET_ALL_GAMES,
    data: gamesData,
})

export const currentGameStatus = (gameStatus: GameResponse) => ({
    type: GET_CURRENT_GAME_STATUS,
    data: gameStatus,
})

export const newGameData = (gameData: any) => ({
    type: POST_NEW_GAME,
    data: gameData,
})

export const gameMoveData = (gameData: GameResponse) => ({
    type: POST_GAME_MOVE,
    data: gameData,
})

export const removeCurrentGameData = () => ({
    type: REMOVE_CURRENT_GAME,
})

export const getAllGames = () => {
    return (dispatch: any) => {
        return axios.get(getBackendBaseUrl() + '/games', getHeaders())
        .then(response => {
            saveToken(response);
            return dispatch(allGamesData(response.data))
        })
    }
}

export const getGameState = (gameId: number) => {
    return (dispatch: any) => {
        return axios.get(getBackendBaseUrl() + '/games/' + gameId, getHeaders())
        .then(response => {
            saveToken(response);
            return dispatch(currentGameStatus(response.data))
        })
    }
}

export const postNewGame = (newGame: GameRequest) => {
    return (dispatch: any) => {
        return axios.post(getBackendBaseUrl() + '/games', 
            { ...newGame },
            getHeaders()
        )
        .then(response => {
            saveToken(response);
            history.push('/list-games');
            return dispatch(newGameData(response.data));
        })
    }
}

export const postGameMove = (moveInfo: GameMoveRequest) => {
    return (dispatch: any) => {
        return axios.put(getBackendBaseUrl() + "/games/" + moveInfo.gameId + '/move',
            { lane: moveInfo.lane},
            getHeaders()
        )
        .then(response => {
            saveToken(response)
            return dispatch(gameMoveData(response.data))
        })
    }
}

export const removeCurrentGame = () => {
    return (dispatch: any) => {
        dispatch(removeCurrentGameData())
    }
}